@import '../../sass/style';

.img-wrapper {
  //   width: 100vw;
}

.animate-img {
  animation: img-zoom 20s linear 0s infinite alternate;
  transform-origin: 100% 0;
  position: relative;
  opacity: 0.8;
  height: 100vh;
  width: auto;

  @include desktop {
    height: 100%;
    width: 100vw;
    // max-width: 100%;
  }
}

@keyframes img-zoom {
  from {
    transform: scale(1);
    transition: transform 20s linear 0s;
  }
  to {
    transform: scale(1.25);
    transition: transform 20s linear 0s;
  }
}
