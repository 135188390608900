/*
 * Text
 */
.bounce {
  animation: bounce 2.2s ease-out 0s;
  transform-origin: center bottom;
  animation-fill-mode: both;
}
@keyframes bounce {
  0% {
    margin-left: 100%;
    transform: translate3d(100%, 0, 0);
  }
  30% {
    margin-left: 0%;
    transform: translate3d(0);
  }
  50%,
  63%,
  75%,
  88%,
  100% {
    animation-timing-function: cubic-bezier(0.21, 0.54, 0.37, 1.13);
    transform: translateZ(0);
  }
  68%,
  70% {
    transform: translate3d(0, -30px, 0);
  }
  80% {
    transform: translate3d(0, -18px, 0);
  }
  94% {
    transform: translate3d(0, -4px, 0);
  }
}

/*
 * Divider
 */
.divider {
  animation: div-slide 1s ease-out 1.2s;
  animation-fill-mode: both;
  background-color: $color-main;
  margin-bottom: 2px;
  height: 3px;
  width: 80px;
  margin-bottom: 12.5px;

  @include tablet {
    width: 100px;
  }
  @include desktop {
    width: 120px;
  }
}
@keyframes div-slide {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0);
  }
}

/*
 * Button Wiggle
 */
.btn-appear {
  animation: voila 0.7s ease 2s;
  animation-fill-mode: both;
  // width: fit-content;
}
@keyframes voila {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom;
  }
  50% {
    transform: rotate(-10deg);
  }
  70% {
    transform: rotate(3deg);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
