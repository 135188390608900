button {
  background: transparent;
  border: 0;

  border-radius: 0;
  color: $light; /*For IE*/
  cursor: pointer;

  letter-spacing: 1px;
  margin: 15px 0;
  padding: 0;
  position: relative;
  text-transform: capitalize;

  a {
    color: $light;
    font-size: 0.8rem;
    font-weight: 700;

    @include tablet {
      font-size: 0.9rem;
    }
  }
}
button > a {
  display: inline-block;
  padding: 10px 30px;
  background: linear-gradient(90deg, $dark 50%, $light 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include tablet {
    padding: 11px 55px;
  }
}
button:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, $light 50%, $dark 50%);
  z-index: -1;
}

button > a,
button:after {
  background-size: 300%;
  background-position: 100%;
  transition: 2.5s ease;
}

button:hover > a,
button:hover:after {
  background-position: 0;
  color: $dark; /*For IE*/
}
